import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';

export const ShareButton: FC = observer((): JSX.Element => {
  const handleShareButtonClick = (): void => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: 'MarketX.pro - Умная платформа управления продажами',
          url: document.location.href,
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error));
    } else {
      console.log('Share not supported on this browser, do it the old way.');
    }
  };

  return (
    <IconButton
      className={'sharingBtn'}
      color="primary"
      aria-label="upload picture"
      onClick={handleShareButtonClick}
      component="span"
      size="large"
    >
      <ShareIcon sx={{ color: theme => (theme.palette.mode === 'light' ? 'primary' : '#fff') }} />
    </IconButton>
  );
});
