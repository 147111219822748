import React, { FC, useState, useEffect, SetStateAction, Dispatch } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { SearchBar } from '@mx-ui/ui';
import { DocumentsSearchStore, NameObjWithTitle } from 'src/store/Documents/DocumentsSearchStore';
import { useDebounceValue } from 'src/utils/hooks/useDebounceValue';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { DialogSearchDocumentsByName } from './DialogSearchDocumentsByName';
import { IconByNameDocument } from './DialogSearchDocumentsListByName';
import { useLocalStorageForSearchDocuments } from '../../hooks/useLocalStorageForSearchDocuments';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { Link as MxLink } from '@mx-ui/ui';

interface DialogSearchDocumentsListProps {
  debounceTime?: number;
  setOpenSearchModal: Dispatch<SetStateAction<boolean>>;
}
export const DialogSearchDocumentsList: FC<DialogSearchDocumentsListProps> = observer(({ debounceTime = 300, setOpenSearchModal }) => {
  const [documentsStorage] = useLocalStorageForSearchDocuments();
  const [value, setValue] = useState('');
  const rootStore = useRootStore();
  const profile = rootStore.getAuth();
  const defaultEmployeeFilter = profile.getDefaultDealsEmployeeFilter();
  const [store] = useState(() => {
    const store = new DocumentsSearchStore(rootStore);
    store.setRouterControl(false);
    store.setRequestInit({ employeeSetCode: defaultEmployeeFilter });
    return store;
  });

  const searchValueD = useDebounceValue(value, debounceTime);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setValue(value);
  };
  useEffect(() => {
    if (value.trim().length >= 2) {
      store.mergeRequest({ query: searchValueD }, true);
    } else if (searchValueD === '') {
      store.mergeRequest({ query: '' }, true);
    }
  }, [searchValueD, store]);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const documentsIsExists = Object.keys(store.documents).some(i => store.documents[i]?.length ?? false);
  const handlePath = (): void => {
    setOpenSearchModal(false);
  };
  return (
    <Box sx={{ width: !matchesSm ? '88vw' : '500px', height: '600px', overflow: 'auto' }}>
      {/* <Box sx={{ maxWidth: '500px', height: '600px', overflow: 'auto' }}> */}
      <Box sx={{ marginLeft: 1, marginRight: 1, display: 'flex', alignItems: 'center' }}>
        <SearchBar
          onChange={handleChange}
          isSticky={false}
          placeholder={'Поиск в документах'}
          value={value}
          endAdornment={
            value && (
              <IconButton aria-label="toggle password visibility" onClick={() => setValue('')}>
                <ClearRoundedIcon />
              </IconButton>
            )
          }
        />
        {!matchesSm && (
          <IconButton sx={{ marginBottom: 2 }} onClick={() => setOpenSearchModal(false)}>
            <CancelPresentationOutlinedIcon color={'primary'} />
          </IconButton>
        )}
      </Box>
      <Box sx={{ marginLeft: 1, marginRight: 1 }}>
        {store.isLoading ? (
          <Box>
            {Array.from(new Array(4)).map((value, index) => (
              <Grid container key={index}>
                <Grid item xs={12} alignSelf="center">
                  <Skeleton variant="text" width={80} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" height={28} sx={{ maxWidth: '100%' }} />
                </Grid>
              </Grid>
            ))}
          </Box>
        ) : (
          <>
            {store.isLoaded && store.request.query?.length && !documentsIsExists ? (
              <Box>Нет данных</Box>
            ) : (
              Object.keys(NameObjWithTitle).map(name => (
                <DialogSearchDocumentsByName key={name} name={name} store={store} setOpenSearchModal={setOpenSearchModal} />
              ))
            )}
          </>
        )}
        {documentsStorage?.length && (
          <Box>
            <Typography variant="body1" color="textPrimary" fontWeight={600} component="a">
              Последние документы
            </Typography>
            {documentsStorage.map(item => (
              <Grid key={item?.docLink} container mb={0.8}>
                <Grid item>
                  <MxLink href={item.docLink} fontWeight={500} color="primary" title={item.docTitle} onClick={handlePath}>
                    <Grid container alignItems={'center'}>
                      <Grid item mr={1}>
                        {IconByNameDocument[item.name]}
                      </Grid>
                      <Grid item>{item.docTitle}</Grid>
                    </Grid>
                  </MxLink>
                </Grid>
                <Grid item>
                  {item.customerTitle && item.customerLink && (
                    <Grid item ml={1}>
                      <MxLink
                        href={item.customerLink}
                        color="text.secondary"
                        typographySx={{ fontSize: 14, fontWeight: 600 }}
                        onClick={handlePath}
                      >
                        {item.customerTitle}
                      </MxLink>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
});
