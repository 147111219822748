import { FC, ReactElement, useCallback, useContext, useState, useMemo, useEffect, useRef, MutableRefObject } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AuthStore } from '../../../store/AuthStore';
import { useAuthStore } from '../../../store/MobxStoreProvider';
import { FeedbackButton } from '../FeedbackButton';
import { TopBar } from '../TopBar';
import Account from '../TopBar/Account';
import DebugBlock from './DebugBlock';
// import Snowfall from 'react-snowfall';
// import { ListItemButton, ListItemWithTooltip } from './ListItem';
// import { setParent } from './setParent';
// import { sortSection } from './sortSection';
import { ListItemWithTooltip, MenuList, MenuOpenContext, TooltipAllItemsMenu, sortSection } from '@mx-ui/navbar';
import { CustomPopper } from 'src/views/deals/components/CustomPopper';
import { DialogSearchDocumentsList } from 'src/components/SearchDocuments/DialogSearchDocumentsList';
import { backOfficeSections, mainSections } from './mainSections';
import { ThemeToggle } from 'src/components/ui/ThemeToggle';
import { debounce } from '@mui/material';
import UAParser from 'ua-parser-js';
import { Link as MxLink } from '@mx-ui/ui';
export const DRAWER_WIDTH = 256;
export const CLOSED_DRAWER_WIDTH = 55;
export interface Section {
  href?: string;
  name?: string;
  isParent?: boolean;
  icon?: ReactElement;
  // info?: ReactElement;
  items?: Section[];
  onClick?: void;
  title: string;
  parentMenu?: string[];
  level?: number;
  access?: string[]; // Группы пользователей, имеющие доступ к пункту меню
  accessFunc?: (auth: AuthStore) => boolean;
}
const componentLink = (href: string, item: JSX.Element): JSX.Element => (
  <div>
    <MxLink href={href}>{item}</MxLink>
  </div>
);

//* MSOMS-2474
// const bottomSections: Section[] = setParent([
//   {
//     title: 'Лайфхаки',
//     name: 'toolsFaq',
//     icon: <Quiz />,
//     href: '/app/tools/faq',
//   },
// ]);
interface NavBarNewProps {
  onClickSearch: () => void;
}
const ScrollWrapper: FC<{ children: JSX.Element[]; scrollbarRef: MutableRefObject<any> }> = ({ children, scrollbarRef }) => {
  // ? ios душит PerfectScrollbar. фиксить это не собираются так что просто показываем div так ios сам свой скролл прикручивает
  const uap = new UAParser(window.navigator.userAgent);
  const os = uap.getOS();
  if (os.name.toLocaleLowerCase() !== 'ios') {
    return (
      <PerfectScrollbar options={{ suppressScrollX: true }} ref={scrollbarRef} style={{ display: 'flex', flexDirection: 'column' }}>
        {children}
      </PerfectScrollbar>
    );
  }
  return <Box sx={{ overflow: 'auto' }}>{children}</Box>;
};
export const NavBarNew: FC<NavBarNewProps> = observer(({ onClickSearch }) => {
  const router = useRouter();
  const auth = useAuthStore();
  const { isOpen: isNavBarOpen } = useContext(MenuOpenContext);

  const availableSections = useMemo(() => {
    const itemWithClickHandle = mainSections?.find(i => i.name === 'sale')?.items?.find(i => i.name === 'searchBySale');
    if (itemWithClickHandle) {
      itemWithClickHandle.onClick = onClickSearch;
    } else {
      console.warn('Невозможно установить обработчик для searchBySale (Navbar)');
    }
    return sortSection(mainSections, auth).map((i, avs) => ({ ...i, indexItem: avs + 1 }));
  }, [auth, onClickSearch]);
  // const availableSections = sortSection(mainSections, auth);

  const availableBackOfficeSections = sortSection(backOfficeSections, auth);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const handleLogout = useCallback((): void => {
    auth.logout();
  }, [auth, router]);

  const scrollbarRef = useRef(null);

  const handleStateEmit = debounce(() => {
    scrollbarRef.current?.updateScroll();
  }, 250);

  return (
    <ScrollWrapper scrollbarRef={scrollbarRef}>
      <TopBar />
      {matches && <Account />}
      <TooltipAllItemsMenu countShowTooltip={3} emitObserveChange={handleStateEmit}>
        <MenuList sections={availableSections} pathname={router.pathname} componentLink={componentLink} />
        <Divider />
        <MenuList sections={availableBackOfficeSections} pathname={router.pathname} componentLink={componentLink} />
        <Divider />

        <Box py={1}>
          <FeedbackButton button={f => feedbackBtnSlot(f, isNavBarOpen)} />
          {/* <MenuList sections={bottomSections} disablePadding pathname={router.pathname} /> */}
        </Box>

        <Divider />
        <Box py={1}>{logoutBtn(handleLogout, isNavBarOpen)}</Box>
        <Divider />

        {/* <Box py={1} mt={4}> */}
        {/*   <TelegrammBtn openSideBarMenu={isNavBarOpen} /> */}
        {/* </Box> */}
      </TooltipAllItemsMenu>
      {isNavBarOpen && (
        <Box p={2}>
          <DebugBlock />
        </Box>
      )}
      <Box mb={1} sx={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
        <ThemeToggle />
      </Box>
    </ScrollWrapper>
  );
});
export const DrawerNavbar = (): JSX.Element => {
  const { isOpen: isNavBarOpen, onChangeMenuOpen } = useContext(MenuOpenContext);
  const theme = useTheme();
  const router = useRouter();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    setTimeout(() => setAnchorEl(anchorEl?.current), 1);
  }, [anchorEl?.current]);
  const handleTooltipClose = (): void => {
    setOpenSearchModal(false);
  };
  const handleClickSearch = useCallback((): void => {
    setAnchorEl(document?.getElementById('Drawer-navbar'));
    if (!matches) {
      onChangeMenuOpen();
    }
    setOpenSearchModal(true);
  }, [matches, onChangeMenuOpen]);
  const snowflake = document?.createElement('img');
  snowflake.src = '/images/snowflake-1.png';
  const snowflake1 = document?.createElement('img');
  snowflake1.src = '/images/snowflake-2.png';
  // новогодние приколюхи
  // const images = [snowflake, snowflake1];
  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      if (isNavBarOpen && !matches) {
        onChangeMenuOpen();
      }
    });
    return () => {
      router.events.off('routeChangeStart', () => {
        console.log('stoped');
      });
    };
  }, [router.events, isNavBarOpen, matches]);

  return (
    <div id={'Drawer-navbar'}>
      {matches ? (
        <Drawer
          variant="permanent"
          open={isNavBarOpen}
          PaperProps={{ sx: { borderRadius: 'inherit', boxShadow: 'none', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' } }}
        >
          {/* новогодние приколюхи */}
          {/* <Snowfall*/}
          {/*  snowflakeCount={8}*/}
          {/*  style={{ zIndex: 1000 }}*/}
          {/*  speed={[0.1, 0.3]}*/}
          {/*  wind={[-0.3, 0.2]}*/}
          {/*  radius={[8.0, 12.0]}*/}
          {/*  images={images}*/}
          {/* />*/}
          <NavBarNew onClickSearch={handleClickSearch} />
        </Drawer>
      ) : (
        <MuiDrawer
          sx={{ width: DRAWER_WIDTH }}
          open={isNavBarOpen}
          PaperProps={{ sx: { width: DRAWER_WIDTH, borderRadius: 'inherit', boxShadow: 'none' } }}
          anchor="left"
          variant="temporary"
          onClose={onChangeMenuOpen}
        >
          {/* новогодние приколюхи */}
          {/* <Snowfall*/}
          {/*  snowflakeCount={20}*/}
          {/*  style={{ zIndex: 1000 }}*/}
          {/*  speed={[0.5, 1.6]}*/}
          {/*  wind={[-0.5, 1.2]}*/}
          {/*  radius={[8.0, 12.0]}*/}
          {/*  images={images}*/}
          {/* />*/}
          <NavBarNew onClickSearch={handleClickSearch} />
        </MuiDrawer>
      )}
      <CustomPopper
        isOpen={openSearchModal}
        anchorEl={anchorEl}
        onClose={handleTooltipClose}
        placement="right-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [8, 8],
            },
          },
        ]}
      >
        <DialogSearchDocumentsList setOpenSearchModal={setOpenSearchModal} />
      </CustomPopper>
    </div>
  );
};

// const TelegramCustomIcon = (): JSX.Element => (
//   <Fab
//     size="small"
//     color={'primary'}
//     className="material-icons-outlined"
//     sx={{
//       background: '-webkit-linear-gradient(top, rgb(68, 179, 224), rgb(30, 149, 211))',
//       marginRight: '20px',
//       marginLeft: '-10px',
//     }}
//   >
//     <TelegramIcon />
//   </Fab>
// );
const feedbackBtnSlot = (handle: () => void, isNavBarOpen = false): JSX.Element => (
  <ListItemWithTooltip
    component={'button'}
    icon={<HelpIcon />}
    title={'Поддержка'}
    name={'helpButton'}
    data-type="tooltip"
    openSideBarMenu={isNavBarOpen}
    onClick={handle}
    sx={{ width: '100%' }}
  />
);
const logoutBtn = (handle: () => void, isNavBarOpen = false): JSX.Element => (
  <ListItemWithTooltip
    component={'button'}
    icon={<ExitToAppIcon />}
    openSideBarMenu={isNavBarOpen}
    title={'Выйти'}
    data-type="tooltip"
    name={'exitButton'}
    onClick={handle}
    sx={{ width: '100%' }}
  />
);
// const TelegrammBtn = ({ openSideBarMenu = true }): JSX.Element => {
//   const title = 'Новости MarketX';
//   // тут не подходит ListItemWithTooltip т.к. стиль для ListItemIconMui нужен дефотлтный
//   return (
//     <Tooltip title={openSideBarMenu ? undefined : title} placement="right">
//       <ListItemButton component="a" href="https://t.me/DEV_MarketX" target={'_blank'}>
//         <ListItemIconMui>
//           <TelegramCustomIcon />
//         </ListItemIconMui>
//         <ListItemText disableTypography primary={title} />
//       </ListItemButton>
//     </Tooltip>
//   );
// };
const openedMixin = (theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${CLOSED_DRAWER_WIDTH}px`,
  // width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    // width: `calc(${theme.spacing(9)} + 1px)`,
    width: `${CLOSED_DRAWER_WIDTH}px`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
