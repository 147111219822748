import React from 'react';
import type { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../store/MobxStoreProvider';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import { Notifications } from '.';
import { StyledBadge } from '../Account';

const NotificationsButton: FC = observer(() => {
  const notificationsStore = useRootStore().getNotificationsStore();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = (): void => {
    setOpen(false);
  };
  const handleTooltipOpen = (): void => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          open={open}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom"
          title={<Notifications store={notificationsStore} isTooltipMode />}
        >
          <IconButton color="primary" aria-label="upload picture" onClick={handleTooltipOpen} component="span" size="large">
            <StyledBadge badgeContent={notificationsStore.total} color={'primary'}>
              <MailIcon sx={{ color: theme => (theme.palette.mode === 'light' ? 'primary' : '#fff') }} />
            </StyledBadge>
          </IconButton>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
});

export { NotificationsButton };
