import React, { FC, useContext } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuIcon from '@mui/icons-material/Menu';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { AppBarColor } from './AppbarColor';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SelectChangeEvent } from '@mui/material/Select';
import { observer } from 'mobx-react-lite';
import { MenuOpenContext } from '@mx-ui/navbar';
import { UserAvatar } from '@mx-ui/ui';
import { NotificationsButton } from './Notifications/NotificationButton';
import { Link as MxLink } from '@mx-ui/ui';
import { ShareButton } from './Notifications/ShareButton';
import { EnvContext } from '../EnvContext';

const PREFIX = 'TopBar';

const classes = {
  root: `${PREFIX}-root`,
  brandName: `${PREFIX}-brandName`,
  toolbar: `${PREFIX}-toolbar`,
  animation: `${PREFIX}-animation`,
  noPadding: `${PREFIX}-noPadding`,
  office: `${PREFIX}-office`,
  brandNameLink: `${PREFIX}-brandNameLink`,
  brandNameIcon: `${PREFIX}-brandNameIcon`,
};

const CssSelect = styled(Select)({
  backgroundColor: 'transparent',
  height: '18px',
  lineHeight: '1',
  marginLeft: '12px',
  '& .MuiSelect-select': {
    maxWidth: '135px',
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '13px',
  },
  '&::before': {
    borderBottom: 'none',
  },
  '&::after': {
    borderBottom: 'none',
  },

  '&:hover:not(.Mui-disabled)::before': {
    borderBottom: '1px solid #fcfcfc',
  },
  '& .MuiSelect-iconStandard': {
    width: '20px',
    height: '20px',
  },
});

const StyledAppBarColor = styled(AppBarColor)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: 0,
  border: 'none',
  [`&.${classes.root}`]: {
    zIndex: theme.zIndex.drawer + 100,
    borderRadius: 'inherit',
    height: '48px',

    paddingLeft: 0,
    paddingRight: 0,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },

  [`& .${classes.brandName}`]: {
    marginLeft: 12,
    fontWeight: 700,
    lineHeight: 1,
    marginTop: '6px',
    // paddingBottom: '6px',
  },

  [`& .${classes.toolbar}`]: {
    paddingRight: 0,
    minHeight: '54px',
    paddingLeft: '0px',
    // paddingLeft: '16px',
  },

  [`& .${classes.animation}`]: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },

  [`& .${classes.noPadding}`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  [`& .${classes.office}`]: {
    marginLeft: 12,
    lineHeight: 1,
    marginBottom: '-2px',
    fontSize: '13px',
  },
}));
const SelectBranchOffice = observer((): JSX.Element => {
  const env = useContext(EnvContext);
  const rootStore = useRootStore();
  const auth = rootStore.getAuth();
  const routerStore = rootStore.getRouter();
  const { profile, myBranchOffices } = auth;
  const handleChange = (event: SelectChangeEvent): void => {
    const newChosenBranchOfficeCode = event.target.value;
    if (profile?.chosenBranchOfficeCode === newChosenBranchOfficeCode) {
      return;
    }
    // обнуление query для страниц Отчеты
    if (routerStore.router.query?.mode || routerStore.router.query?.selectedCrumbCode) {
      routerStore.router
        .replace(
          {
            pathname: routerStore.router.pathname,
            query: { ...routerStore.router.query, mode: undefined, selectedCrumbCode: undefined },
          },
          undefined,
          { shallow: true }
        )
        .then(() => {
          auth.setMyBranchOffice(newChosenBranchOfficeCode);
        });
      return;
    }
    if (routerStore.router.asPath.indexOf('app/catalog') !== -1 || routerStore.router.asPath.indexOf('app/product') !== -1) {
      const newQuery = { ...routerStore.router.query };
      if (newQuery.warehouseCode && !newQuery.branchOfficeCode) {
        delete newQuery.warehouseCode;
      }
      routerStore.router
        .replace(
          {
            pathname: routerStore.router.pathname,
            query: newQuery,
          },
          undefined,
          { shallow: true }
        )
        .then(() => {
          auth.setMyBranchOffice(newChosenBranchOfficeCode);
        });
      return;
    }
    auth.setMyBranchOffice(newChosenBranchOfficeCode);
  };
  const currentOfficeInList = myBranchOffices.filter(o => o.code === profile.chosenBranchOfficeCode).length > 0;
  return (
    <FormControl variant="standard">
      <CssSelect
        title={profile?.chosenBranchOfficeName || profile?.branchOfficeName}
        value={profile?.chosenBranchOfficeCode || profile?.branchOfficeCode}
        onChange={handleChange}
        label="Офис"
        sx={{
          color: env === 'prod' ? 'text.primary  !important' : '#fff !important',
          // '& .MuiSelect-iconStandard': { color: env === 'prod' ? 'text.primary  !important' : '#fff !important' },
          '& .MuiSelect-iconStandard': { display: 'none' },
        }}
      >
        {myBranchOffices.map(branchOffice => (
          <MenuItem value={branchOffice.code} key={branchOffice.code}>
            {branchOffice.name}
          </MenuItem>
        ))}
        {!currentOfficeInList && Boolean(profile?.chosenBranchOfficeCode) && (
          // Особый случай, когда текущего филиала нет в списке для переключения
          <MenuItem value={profile?.chosenBranchOfficeCode} key={profile?.chosenBranchOfficeCode} disabled={true}>
            {profile?.chosenBranchOfficeName || profile?.chosenBranchOfficeCode}
          </MenuItem>
        )}
      </CssSelect>
    </FormControl>
  );
});

const LinkForTopbar = (): JSX.Element => {
  return (
    <MxLink
      href="/"
      typographySx={{
        display: 'inline-flex',
        lineHeight: 'inherit',
        fontSize: 'inherit',
        position: 'relative',
      }}
    >
      <img src="/android-chrome-192x192.png" width={54} height={54} alt={''} />
      {/* <img*/}
      {/*  src={'/images/rb_542.png'}*/}
      {/*  width={54}*/}
      {/*  height={'100%'}*/}
      {/*  alt={''}*/}
      {/*  style={{*/}
      {/*    position: 'absolute',*/}
      {/*    top: '0',*/}
      {/*    left: '0',*/}
      {/*  }}*/}
      {/* />*/}
    </MxLink>
  );
};
export const TopBar: FC = observer(() => {
  const env = useContext(EnvContext);
  const rootStore = useRootStore();
  const { isOpen: open, onChangeMenuOpen } = useContext(MenuOpenContext);
  const auth = rootStore.getAuth();
  const { profile, isShowSwitchingBetweenBranches } = auth;

  return (
    <StyledAppBarColor sx={{ zIndex: 1199 }}>
      <Toolbar
        className={clsx(classes.toolbar, {
          [classes.noPadding]: !open,
        })}
        variant="regular"
      >
        <Zoom in={!open} style={{ position: 'absolute' }}>
          <IconButton color={env === 'prod' ? 'primary' : 'inherit'} style={{ marginLeft: 2 }} onClick={onChangeMenuOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Zoom>
        <Fade in={open} unmountOnExit>
          <Box sx={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', lineHeight: 1, fontSize: 0 }}>
            <LinkForTopbar />
            <Box className={classes.animation} sx={{ height: '10px', alignSelf: 'center' }}>
              {isShowSwitchingBetweenBranches ? (
                <SelectBranchOffice />
              ) : (
                <Typography className={clsx(classes.office)} color={env === 'prod' ? 'text.primary' : '#fff'}>
                  {profile?.chosenBranchOfficeName || profile?.branchOfficeName}
                </Typography>
              )}
            </Box>
            <IconButton
              sx={{
                position: 'absolute',
                right: 0,
                bottom: '3px',
                top: '8px',
                p: '8px',
                height: '40px',
                color: env === 'prod' ? 'text.primary' : '#fff',
              }}
              onClick={onChangeMenuOpen}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        </Fade>
        {/* </Hidden> */}
      </Toolbar>
    </StyledAppBarColor>
  );
});
export const TopBarMobile: FC = observer(() => {
  const env = useContext(EnvContext);
  const rootStore = useRootStore();
  const { onChangeMenuOpen } = useContext(MenuOpenContext);
  const auth = rootStore.getAuth();
  const { profile, isShowSwitchingBetweenBranches } = auth;

  return (
    <StyledAppBarColor>
      <Toolbar variant="regular" sx={{ minHeight: '54px' }}>
        <Box display={'flex'} alignItems="center" justifyContent={'space-between'} width="100%">
          <Box sx={{ display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
            {/* <LinkForTopbar /> */}
            <IconButton
              onClick={onChangeMenuOpen}
              size="medium"
              sx={{
                width: '32px',
                height: '32px',
                color: 'primary',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box className={classes.animation} sx={{ height: '12px' }}>
              {isShowSwitchingBetweenBranches ? (
                <SelectBranchOffice />
              ) : (
                <Typography className={clsx(classes.office)} color={env === 'prod' ? 'text.primary' : '#fff'}>
                  {profile?.chosenBranchOfficeName || profile?.branchOfficeName}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display={'flex'}>
            <Box>
              <ShareButton />
            </Box>
            <Box mr={1}>
              <NotificationsButton />
            </Box>

            <MxLink href={'/app/profile'} variant="body2" typographySx={{ width: '100%' }}>
              <UserAvatar
                surname={auth.profile?.employee.surname}
                name={auth.profile?.employee.name}
                src={auth.profile?.employee?.avatarUrl}
              />
            </MxLink>
          </Box>
        </Box>
      </Toolbar>
    </StyledAppBarColor>
  );
});
