import React, { useCallback, useState } from 'react';
import type { FC } from 'react';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import { useRootStore } from 'src/store/MobxStoreProvider';
import { AppBarColor } from './AppbarColor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { ProductItemStore } from 'src/store/ProductItemStore';
import { CatalogProductBindingDialog } from 'src/components/Catalog/CatalogProductBindingDialog';
const PREFIX = 'AppBarDeal';

const classes = {
  close: `${PREFIX}-close`,
  appbar: `${PREFIX}-appbar`,
  toolbar: `${PREFIX}-toolbar`,
};

const StyledAppBarColor = styled(AppBarColor)(({ theme }) => ({
  [`& .${classes.close}`]: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    fontSize: '14px',
  },
  [`& .${classes.toolbar}`]: {
    minHeight: '54px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  [`&.${classes.appbar}`]: {
    boxShadow: 'none',
    border: 'none',

    borderRadius: 0,
    position: 'sticky',
    zIndex: 1500,
    top: 0,
  },
}));

interface AppBarDealProps {
  label: React.ReactNode;
  productStore?: ProductItemStore;
}
const AppBarDeal: FC<AppBarDealProps> = observer(({ label, productStore }): JSX.Element => {
  const rootStore = useRootStore();
  const topBarStore = rootStore.getTopBarStore();
  const { push } = useRouter();

  const [isBindingDialogOpen, setIsBindingDialogOpen] = useState(false);

  const onClose = useCallback((): void => {
    const entityCode = topBarStore.entityCode;
    const url = `/app/${topBarStore.entityType}${topBarStore.entityType === 'freeze' ? '' : 's'}/`;
    if (entityCode && topBarStore.entityType !== 'product') {
      push(url + encodeURIComponent(entityCode)).then(() => {
        topBarStore.resetStore();
      });
    }
    if (topBarStore.entityType === 'product') {
      if (Object.keys(productStore.selectedProduct).length) {
        setIsBindingDialogOpen(true);
      } else {
        push(`/app/product?productCode=${productStore.topBarEntityStore.productItemStore.product?.code}`);
      }
    }
  }, [topBarStore]);
  return (
    <StyledAppBarColor className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        {label}
        <Button
          variant={'contained'}
          color="primary"
          onClick={onClose}
          aria-label="close"
          className={classes.close}
          size="large"
          endIcon={<ArrowBackIcon />}
        >
          Вернуться&#160;
          {topBarStore.entityType === 'freeze' && 'во фриз'}
          {topBarStore.entityType === 'deal' && 'в заявку'}
          {topBarStore.entityType === 'agreement' && 'в соглашение'}
          {topBarStore.entityType === 'client' && 'к клиенту'}
        </Button>
        {productStore && (
          <CatalogProductBindingDialog
            productStore={productStore}
            isOpen={isBindingDialogOpen}
            onClose={() => setIsBindingDialogOpen(false)}
          />
        )}
      </Toolbar>
    </StyledAppBarColor>
  );
});
export { AppBarDeal };
