import { useContext } from 'react';
import { useTheme } from '@mui/material';
import { ColorModeContext } from '../DashboardLayout/ThemeProviderMui';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

export const ThemeToggle = (): JSX.Element => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return (
    <div>
      <DarkModeSwitch checked={theme.palette.mode === 'dark'} onChange={colorMode.toggleColorMode} size={24} />
    </div>
  );
};
