import Typography from '@mui/material/Typography';
import React from 'react';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { observer } from 'mobx-react-lite';
import { grey } from '@mui/material/colors';

const DebugBlock = observer((): JSX.Element => {
  const wsStore = useRootStore().getWebSocket();
  const f1ClientStore = useRootStore().getF1ClientStore();
  const versionStore = useRootStore().getVersion();
  const frontVersion = versionStore.getFront();
  const backVersion = versionStore.getBack();
  return (
    <>
      <Typography variant="h6" color={grey[400]} style={{ fontSize: '10px', textAlign: 'center' }}>
        fr {frontVersion.isLoaded ? frontVersion.actualImageTime : '-'}
        {frontVersion.updateRequired && <span title={frontVersion.actualImageTime}> u</span>}
        {frontVersion.lastError && <span title="has error"> e</span>}
      </Typography>
      <Typography variant="h6" color={grey[400]} style={{ fontSize: '10px', textAlign: 'center' }}>
        bk {backVersion.isLoaded ? backVersion.buildTime : '-'}
        {backVersion.lastError && <span title="has error"> e</span>}
      </Typography>
      <Typography variant="h6" color={grey[400]} style={{ fontSize: '10px', textAlign: 'center' }}>
        ws {wsStore.lastConnectNumber}
        {wsStore.isConnected ? '+ ' : '- '}
        {f1ClientStore.lastConnectNumber}
        {f1ClientStore.isConnected ? '+' : '-'}
      </Typography>
    </>
  );
});

export default DebugBlock;
