import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { ProductItemStore } from 'src/store/ProductItemStore';

type CatalogProductBindingDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  productStore?: ProductItemStore;
};

export const CatalogProductBindingDialog: FC<CatalogProductBindingDialogProps> = ({ isOpen, onClose, productStore }) => {
  const router = useRouter();

  const handleSuccess = (): void => {
    productStore
      .setCatalogProductsItemLeadLink(productStore.topBarEntityStore.productItemStore.product?.code, productStore.selectedProduct?.code)
      .then(() => router.push(`/app/product?productCode=${productStore.topBarEntityStore.productItemStore.product?.code}`));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle sx={{ padding: '8px 24px' }}>
        <Typography variant={'body1'} fontWeight={500}>
          Подтверждение привязки товара-лида
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <>
          <Typography color={'text.primary'}>
            Вы уверены, что хотите связать товар-лид{' '}
            <Typography color={'text.secondary'}>{productStore.topBarEntityStore.productItemStore.product?.title}</Typography>с товаром{' '}
            <Typography color={'text.secondary'}>
              {`${productStore.selectedProduct?.code} ${productStore.selectedProduct?.title}`}?
            </Typography>
          </Typography>
          <Typography sx={{ mt: 2 }}>После подтверждения, редактирование привязки будет недоступно</Typography>
        </>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="contained" onClick={handleSuccess}>
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
};
