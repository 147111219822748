import React, { useContext } from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { alpha, styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import { UserAvatar } from '@mx-ui/ui';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
import { MenuOpenContext, isNavItemSelected } from '@mx-ui/navbar';
import { useRouter } from 'next/router';
import MailIcon from '@mui/icons-material/Mail';
import { Notifications } from './Notifications';

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme, badgeContent }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      // borderRadius: '50%',
      borderRadius: `${Number(badgeContent) < 10 ? '50%' : Number(badgeContent) > 10 && Number(badgeContent) <= 99 ? '43%' : '36%'}`,
      animation: `ripple 1.6s infinite ease-in-out`,
      border: `1px solid ${theme.palette.primary.main}`,
      content: '""',
    },
  },
})) as any;
const linkProfile = '/app/profile';
const Account = observer(() => {
  const auth = useAuthStore();
  const { isOpen: isMenuOpen } = useContext(MenuOpenContext);
  const notificationsStore = useRootStore().getNotificationsStore();
  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    const target = e.target as HTMLDivElement;
    if (target.className.indexOf('MuiBadge-badge') !== -1) {
      e.stopPropagation();
      setOpen(true);
    } else {
      router.push('/app/profile');
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = (): void => {
    setOpen(false);
  };

  const handleTooltipOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    setOpen(true);
  };
  const isSelect = isNavItemSelected(router.pathname, linkProfile);
  return (
    <Box>
      {/* <RouterLink legacyBehavior href={'/app/profile'} passHref>
        <Link component="a" variant="body2" sx={{ width: '100%' }}> */}
      <Box
        onClick={handleClick}
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '12px',
            mt: 1,
            transition: 'padding 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',

            ...(isMenuOpen
              ? {
                  padding: '4px 10px',
                  mx: 1,
                }
              : {
                  padding: '0px',
                }),
          },
          theme => ({
            backgroundColor: isSelect ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) : undefined,
            '&:hover': {
              backgroundColor: !isSelect
                ? theme.palette.action.hover
                : alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
            },
          }),
        ]}
      >
        <Box p={1} sx={{ cursor: 'pointer' }}>
          <StyledBadge
            badgeContent={!isMenuOpen ? notificationsStore.total : undefined}
            sx={{
              '& .MuiBadge-badge': {
                right: '6px',
              },
            }}
            color="primary"
          >
            <UserAvatar
              surname={auth.profile?.employee.surname}
              name={auth.profile?.employee.name}
              src={auth.profile?.employee?.avatarUrl}
            />
          </StyledBadge>
        </Box>

        <Typography
          title={auth?.profile?.name}
          color="text.secondary"
          variant="body2"
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 150, cursor: 'pointer' }}
        >
          {(auth.isLoaded && <>{auth?.profile?.name || '(имя не задано)'}</>) || <Skeleton variant="text" width={110} />}
        </Typography>
        {isMenuOpen && (
          //* дублируется логика из NotificationsButton, т.к. нужно было вывести tooltip из под события клика
          <IconButton color="primary" aria-label="upload picture" onClick={handleTooltipOpen} component="span" size="large">
            <StyledBadge badgeContent={notificationsStore.total} color="primary">
              <MailIcon />
            </StyledBadge>
          </IconButton>
        )}
        {/* {isMenuOpen && <NotificationsButton />} */}
      </Box>
      {/* </Link>
      </RouterLink> */}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <LightTooltip
            open={open}
            onClose={handleTooltipClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom"
            title={<Notifications store={notificationsStore} isTooltipMode />}
          >
            <div></div>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </Box>
  );
});

export default Account;
