import { Dispatch, FC, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { DocumentsSearchStore, NameObjWithTitle } from 'src/store/Documents/DocumentsSearchStore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DialogSearchDocumentsListByName } from './DialogSearchDocumentsListByName';
import { Link as MxLink } from '@mx-ui/ui';

interface DialogSearchDocumentsByNameProps {
  store: DocumentsSearchStore;
  countOfLabels?: number;
  name: string;
  setOpenSearchModal: Dispatch<SetStateAction<boolean>>;
}
export const DialogSearchDocumentsByName: FC<DialogSearchDocumentsByNameProps> = observer(
  ({ store, name, countOfLabels = 5, setOpenSearchModal }) => {
    const documentObj = store.documents[name];
    const [showAllCheckbox, setShowAllCheckbox] = useState(false);
    const handleChangeViewAll = (): void => {
      setShowAllCheckbox(!showAllCheckbox);
    };
    if (documentObj === undefined || documentObj === null || documentObj.length == 0) {
      return <Box key={name}></Box>;
    }
    const labelList = store.documents[name] || [];
    const link = `/app/${name}${store.request?.query ? '?query=' + store.request.query : ''}`;
    return (
      <Box key={name}>
        <MxLink href={link} variant="body1" color="textPrimary" fontWeight={600}>
          {NameObjWithTitle[name]}
        </MxLink>
        <Box mt={1}>
          {showAllCheckbox
            ? labelList.map(document => (
                <DialogSearchDocumentsListByName
                  key={document.mdmCode || document.code || document.documentNumber}
                  document={document}
                  store={store}
                  name={name}
                  setOpenSearchModal={setOpenSearchModal}
                />
              ))
            : labelList
                .slice(0, showAllCheckbox ? labelList.length : countOfLabels)
                .map(document => (
                  <DialogSearchDocumentsListByName
                    key={document.mdmCode || document.code || document.documentNumber}
                    document={document}
                    store={store}
                    name={name}
                    setOpenSearchModal={setOpenSearchModal}
                  />
                ))}
          <Box display="flex" flexDirection={'column'}>
            {labelList.length > countOfLabels && (
              <>
                {showAllCheckbox ? (
                  <Button sx={{ textTransform: 'none' }} variant="text" onClick={handleChangeViewAll}>
                    Свернуть
                    <KeyboardArrowUpIcon />
                  </Button>
                ) : (
                  <Button sx={{ textTransform: 'none' }} variant="text" onClick={handleChangeViewAll}>
                    Показать еще
                    <KeyboardArrowDownIcon />
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);
