import { AuthStore } from '../AuthStore';
import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import { DocumentsSearchResponse } from 'src/api/marketx';
import { dateEnd, dateStart, defaultQuickDate } from './utils/defaultQuickDate';
import { formatDateSwagger, formatDateSwaggerZ, formatDateYmd } from '@mx-ui/helpers';
import { BillListRequest } from './BillListStore';
import { RouterStore } from '../RouterStore';
import { quickDateRanges, RangeVariant } from '../../components/SelectDateRange/MenuButtonNew';
import { MyApprovalsAskingStore } from '../Deals/MyApprovalsAsking';

export const NameObjWithTitle = {
  contracts: 'Договоры',
  agreements: 'Соглашения',
  deals: 'Заявки на счет',
  bills: 'Счета',
  salesInvoices: 'Расходные накладные',
  shipments: 'Отгрузки',
  returns: 'Возвраты',
  payments: 'Оплаты',
  debts: 'Списание долга',
  approvals: 'Согласования',
};

export class DocumentsSearchRequest {
  query?: string;
  employeeSetCode?: string; // Фильтр по реализатору
  quickRange?: string;
  dateFrom?: Date;
  dateTo?: Date;
  limit?: number;
}

export class DocumentsSearchStore {
  apiStore: ApiStore;
  routerStore: RouterStore;
  distributorsStore: MyApprovalsAskingStore;
  isLoaded = false;
  isLoading = false;
  requestInit: DocumentsSearchRequest = {};
  routerControlEnabled = false;
  request: DocumentsSearchRequest = {
    query: '',
    dateFrom: dateStart,
    dateTo: dateEnd,
    limit: 10,
  };
  authStore: AuthStore;
  documents: DocumentsSearchResponse = {};
  quickRange: RangeVariant = defaultQuickDate;
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.routerStore = rootStore.getRouter();
    this.authStore = rootStore.getAuth();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }
  loadMyApprovalsAskingStore(rootStore: RootStore, initEmployee?: string): void {
    this.distributorsStore = new MyApprovalsAskingStore(rootStore);
    this.distributorsStore.loadMyList(initEmployee);
  }

  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
  }

  setRequestInit(req: DocumentsSearchRequest): void {
    set(this.request, req);
  }

  titleByDocument(document: Record<string, string>, name: string): string {
    switch (name) {
      case 'agreements':
        return `${document.code}${' с ' + formatDateYmd(document.validFrom) + ' по ' + formatDateYmd(document.validUntil)}`;

      case 'approvals':
        return `${document.code}${' с ' + formatDateYmd(document.validFrom) + ' по ' + formatDateYmd(document.validUntil)}`;

      case 'bills':
        return `${document.mdmCode}${' от ' + formatDateSwaggerZ(document.documentDate)}`;

      case 'contracts':
        return `${document.contractNumber ? document.contractNumber : 'б/н'}${
          document.date ? ` от ` + formatDateSwaggerZ(document.contractDate) : ''
        }`;

      case 'deals':
        return `${document.code}${document.documentDate ? ' от ' + formatDateSwaggerZ(document.documentDate) : ''}`;

      case 'debts':
        return `${document.documentNumber}${` от ` + formatDateSwaggerZ(document.documentDate)}`;
      case 'payments':
        return `${document.documentNumber}${` от ` + formatDateSwaggerZ(document.documentDate)}`;

      case 'returns':
        return `${document.documentNumber}${` от ` + formatDateSwaggerZ(document.documentDate)}`;

      case 'salesInvoices':
        return `${document.documentNumber}${` от ` + formatDateSwaggerZ(document.documentDate)}`;

      case 'shipments':
        return `${document.documentNumber}${` от ` + formatDateSwaggerZ(document.documentDate)}`;

      default:
        return '';
    }
  }

  customerTitleAndLink(document: Record<string, string>): {
    link: string;
    title: string;
  } {
    return {
      link: document?.customerCode ? `/app/clients/${document?.customerCode}` : '',
      title: document?.companyShortName || '',
    };
  }

  actualizeRouter(req: BillListRequest): void {
    const reqInit = toJS(this.requestInit);
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();
    if (req.query) {
      params.set('query', req.query);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }
    if (req.employeeSetCode && req.employeeSetCode !== reqInit.employeeSetCode) {
      params.set('employeeSet', req.employeeSetCode);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/sales';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }

  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }

  mergeRequest(req: BillListRequest, isFastSearch = false): void {
    set(this.request, req);
    this.actualizeRouter(toJS(this.request));
    this.refresh(isFastSearch);
  }

  refresh(isFastSearch = false): void {
    this.isLoading = true;
    const req = toJS(this.request);
    if (req.query === '' && isFastSearch) {
      this.setLoadResult({});
      return;
    }
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    this.apiStore
      .apiDocuments()
      .documentsSearch(
        this.authStore.profile.chosenBranchOfficeCode || this.authStore.profile.branchOfficeCode,
        req.query || undefined,
        req.employeeSetCode || undefined,
        req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        req.limit
      )
      .then(res => {
        this.setLoadResult(res.data);
      });
  }

  linkByDocument(document: Record<string, string>, name: string): string {
    switch (name) {
      case 'agreements':
        return `/app/${name}/${encodeURIComponent(document.code)}`;

      case 'approvals':
        return `/app/${name}/${encodeURIComponent(document.code)}`;

      case 'bills':
        return `/app/${name}/${encodeURIComponent(document.mdmCode)}`;

      case 'contracts':
        return `/app/${name}/${document.code}?customerCode=${document.participantCode}`;
      // return `/app/${name}/${document.code}?customerCode=${document.participantCode}&branchOfficeCode=${contract.branchOfficeCode}`

      case 'deals':
        return `/app/${name}/${encodeURIComponent(document.code)}`;

      case 'debts':
        return `/app/${name}/${encodeURIComponent(document.code)}?debtDocumentNumber=${encodeURIComponent(document.documentNumber)}`;
      case 'payments':
        // href = {`/app/payments/${encodeURIComponent(payment.documentNumber)}?paymentCode=${encodeURIComponent(payment.code)}`
        // }
        return `/app/${name}/${encodeURIComponent(document.documentNumber)}?paymentCode=${encodeURIComponent(document.code)}`;

      case 'returns':
        return `/app/returns/${encodeURIComponent(document.documentNumber)}?returnCode=${encodeURIComponent(document.code)}`;

      case 'salesInvoices':
        return `/app/sales-invoice/${encodeURIComponent(document.documentNumber)}`;

      case 'shipments':
        return `/app/${name}/${encodeURIComponent(document.documentNumber)}`;

      default:
        return '';
    }
  }

  setLoadResult(res: DocumentsSearchResponse): void {
    runInAction(() => {
      this.isLoaded = true;
      this.isLoading = false;
      this.documents = res || {};
    });
  }
}
