import React, { useCallback, useState } from 'react';
import StyleContainer from '../../styles/StyleContainer';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const CookieBar = (): JSX.Element => {
  const [accepted, setAccepted] = useLocalStorage('cookieAccept', true);
  const [state, setState] = useState<boolean>(!!accepted);

  const onClickHandler = useCallback((): void => {
    setAccepted(true);
    setState(true);
  }, [setAccepted, setState]);

  return state ? (
    <></>
  ) : (
    <StyleContainer
      no-padding
      middle
      height="7vh"
      backgroundColor="black"
      color="white"
      width="100vw"
      style={{ zIndex: 50000, position: 'fixed', bottom: '0', left: '0' }}
    >
      <StyleContainer alignItems="flex-start">
        Продолжая использовать этот сайт, вы подтверждаете, что ознакомлены и принимаете условия документа &quot;Политика обработки
        персональных данных&quot;, содержащего, в частности, порядок использования файлов cookie.
      </StyleContainer>
      <StyleContainer>
        <button style={{ backgroundColor: 'white', color: 'black', border: '0', height: '4vh', width: '7vw' }} onClick={onClickHandler}>
          OK
        </button>
      </StyleContainer>
    </StyleContainer>
  );
};

export default CookieBar;
