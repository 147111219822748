import React from 'react';
import type { CSSProperties, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import { observer } from 'mobx-react-lite';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { defaultPageSize, NotificationsStore } from 'src/store/NotificationsStore';
// import { formatYmdHiLZ } from '@mx-ui/helpers';

import { UserNotificationsItem } from 'src/api/marketx';
import { formatYmdHiLZ } from '@mx-ui/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouter } from 'next/router';
import { Link as MxLink } from '@mx-ui/ui';

const PREFIX = 'Notifications';

const classes = {
  message: `${PREFIX}-message`,
  content: `${PREFIX}-content`,
  header: `${PREFIX}-header`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.message}`]: {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    display: '-webkit-box',
    overflow: 'hidden',
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(1),
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.header}`]: {
    padding: theme.spacing(1),
  },
}));
const listStyle = { overflowX: 'hidden', overflowY: 'hidden', padding: 1 } as CSSProperties;
const listSkeleton = <Skeleton variant="rectangular" sx={{ margin: '10px' }} />;

interface NotificationsProps {
  store: NotificationsStore;
  isTooltipMode?: boolean;
}
export const Notifications: FC<NotificationsProps> = observer(({ store, isTooltipMode = false }) => {
  const router = useRouter();
  const handleReadAllMessage = (): void => {
    store.readAllMessages();
  };
  const handleReadMessage = (notification: UserNotificationsItem): void => {
    store.readMessage(notification);
  };

  const handleClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, notification: UserNotificationsItem): void => {
    e.preventDefault();
    router
      .push(notification.url)
      .then(() => {
        store.readMessage(notification);
      })
      .then(() => {
        handleAuxClickLink(e, notification);
      });
  };
  const handleAuxClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, notification: UserNotificationsItem): void => {
    store.readMessage(notification);
  };
  let items = [];
  if (isTooltipMode) {
    items = store.notifications.slice(0, defaultPageSize);
  } else {
    items = store.notifications;
  }

  return (
    <StyledGrid container direction="column">
      <Grid container justifyContent="space-between" className={classes.header}>
        <Box alignSelf="center">
          <Typography align="center" variant="h6">
            Уведомления
          </Typography>
        </Box>
        <Box>
          {isTooltipMode && (
            <MxLink href="/app/profile?selectedTabUrl=notifications">
              <Button size="small" sx={{ mx: 1 }}>
                Показать все
              </Button>
            </MxLink>
          )}
          <Button size="small" onClick={handleReadAllMessage} disabled={!store.total}>
            Прочитать все
          </Button>
        </Box>
      </Grid>
      <Divider />
      {!store.isLoaded && store.isLoading && (
        <Grid item container px={1}>
          {[1, 2, 3].map(i => {
            return (
              <Grid key={i} xs={12} item mt={1}>
                <Skeleton variant="rectangular" height={120} sx={{ borderRadius: '15px' }} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {store.isLoaded && (
        <InfiniteScroll
          dataLength={store.notifications.length}
          next={store.loadMore}
          hasMore={!isTooltipMode ? store.hasMore : false}
          style={listStyle}
          loader={listSkeleton}
        >
          <Grid
            container
            direction="column"
            className={classes.content}
            {...(isTooltipMode && { sx: { flexWrap: 'nowrap', maxHeight: '400px', maxWidth: '640px', overflow: 'auto' } })}
          >
            {items.map(notification => (
              <Box key={notification.code} component="div">
                <Alert
                  severity={'info'}
                  // severity={notification.type || 'info'}
                  action={
                    !notification.read ? (
                      <IconButton
                        title="Прочитать сообщение"
                        color="primary"
                        aria-label="read"
                        size="small"
                        onClick={() => handleReadMessage(notification)}
                      >
                        <FiberManualRecordIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      ''
                    )
                  }
                >
                  <AlertTitle>
                    <MxLink variant="body1" color="textPrimary" onClick={e => handleClickLink(e, notification)} href={notification.url}>
                      {notification.title}
                    </MxLink>
                  </AlertTitle>

                  {notification.text && (
                    <Typography className={classes.message} title={notification.text}>
                      {notification.text}
                    </Typography>
                  )}
                  {notification.sentAt && (
                    <Typography variant="subtitle2" color="text.secondary" component="span">
                      {formatYmdHiLZ(notification.sentAt)}
                    </Typography>
                  )}
                </Alert>
              </Box>
            ))}
            {!store.notifications.length && !store.isMoreLoading && !store.isLoading && (
              <Box alignItems="center" justifyContent="center" display="flex">
                <Typography align="center">Нет Уведомлений</Typography>
              </Box>
            )}
          </Grid>
        </InfiniteScroll>
      )}
    </StyledGrid>
  );
});
