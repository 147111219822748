import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import CallEndIcon from '@mui/icons-material/CallEnd';
import Draggable from 'react-draggable';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { Timer } from './Timer';
import { Link as MxLink } from '@mx-ui/ui';
export const CallDialog: FC = observer((): JSX.Element => {
  const myRef = useRef(null);

  const rootStore = useRootStore();
  const callStore = rootStore.getCallStore();
  const call = callStore.data?.call;
  const handleHangupCall = (): void => {
    callStore.hangupCall();
  };
  const [heightDialog, setHeightDialog] = useState(0);
  useEffect(() => {
    if (myRef.current) {
      setHeightDialog(myRef.current.getBoundingClientRect().height);
    }
  }, [myRef.current]);

  const [callDialogPos, setCallDialogPos] = useLocalStorage<string>('callDialogPos');
  const [minimize, setMinimize] = useLocalStorage<boolean>('callDialogMinimize', false);
  const handleMinimizeDialog = (): void => {
    setMinimize(true);
  };
  const handleMinimizeDialog1 = (): void => {
    setMinimize(false);
    if (-(window.innerHeight - 200 - 16 - 16) > y) {
      setY(-(window.innerHeight - 200 - 16 - 16));
    }
  };
  const parsee = JSON.parse(callDialogPos || '{}');
  const [x, setX] = useState(parsee.x || 0);
  const [y, setY] = useState(parsee.y || 0);

  const handleStop = (event, dragElement): void => {
    setX(dragElement.x);
    setY(dragElement.y);
    console.log(
      'col dragElement',
      dragElement,
      dragElement.node.getBoundingClientRect().height,
      heightDialog,
      window.screen.height,
      window.innerHeight
    );
    setCallDialogPos(JSON.stringify({ x: dragElement.x, y: dragElement.y }));
  };

  return (
    <Draggable bounds="body" onStop={handleStop} position={{ x: x, y: y }} handle=".draggable-dialog-title">
      <Box className="dialog-call" sx={{ position: 'fixed', bottom: '0', right: '0', margin: '16px', zIndex: 10000 }}>
        {callStore.showCallDialog ? (
          <>
            {minimize ? (
              <Paper
                sx={{
                  borderRadius: '16px',
                  display: 'flex',
                  backgroundColor: '#1e1e1ed4',
                  padding: '4px 12px',
                  alignItems: 'center',
                  width: 300,
                }}
              >
                <Grid container justifyContent={'center'} className="draggable-dialog-title" sx={{ cursor: 'move' }}>
                  <Grid item xs={10} container flexDirection={'column'} justifyContent={'space-between'}>
                    <Grid item container>
                      {callStore.data.call && callStore.callMatchOne && (
                        <Box sx={{ width: '100%' }}>
                          <Typography variant="h5" sx={{ color: 'white' }}>
                            {callStore.nameClientCall}
                          </Typography>
                        </Box>
                      )}
                      {callStore.data.call && callStore.callMatchSeveral && (
                        <Box sx={{ width: '100%' }}>
                          <Typography variant="h5" gutterBottom fontSize={'15px'} color="white">
                            Найдено несколько совпадений
                          </Typography>
                        </Box>
                      )}
                      {callStore.data.call && !callStore.callMatchSeveral && !callStore.callMatchOne && (
                        <Box sx={{ width: '100%' }}>
                          <Typography variant="h5" fontSize={'15px'} color="white">
                            Совпадений не найдено
                          </Typography>
                        </Box>
                      )}
                      <MxLink
                        href={`/app/clients?phone=${encodeURIComponent(call.callerIDNum.replace('+', ''))}`}
                        variant="body2"
                        color="#e1e1e1"
                        typographySx={{ textAlign: 'center' }}
                      >
                        {!callStore.callMatchSeveral && !callStore.callMatchOne ? 'Выбрать вручную' : 'Показать всех'}
                      </MxLink>
                    </Grid>
                    <Grid item>
                      {call?.answerAt && <Timer date={call.answerAt} color="white" variant="h5" sx={{ fontWeight: '400' }} />}
                    </Grid>
                  </Grid>
                  <Grid item xs={'auto'} container flexDirection={'column'} alignItems={'flex-end'}>
                    <Grid item>
                      <IconButton
                        aria-label="minimize"
                        size="small"
                        onClick={handleMinimizeDialog1}
                        title="Развернуть"
                        sx={{ color: '#e1e1e1', height: '30px', width: '30px' }}
                        // onClick={handleCloseZoom}
                      >
                        <OpenInFullIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleHangupCall}
                        color="error"
                        size="small"
                        sx={{ minWidth: '32px', padding: '2px', margin: '0 4px' }}
                        variant="contained"
                      >
                        <CallEndIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ) : (
              <Paper
                ref={myRef}
                sx={{
                  width: 300,
                  minHeight: 200,
                  backgroundColor: '#000000ad',
                  // backgroundColor: '#0f0f0fbf',
                  overflow: 'auto',
                  color: 'white',
                  zIndex: 999999,
                  borderRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                elevation={12}
              >
                <Box
                  className="draggable-dialog-title"
                  sx={{
                    padding: '2px 16px 5px 15px',
                    borderRadius: '16px',
                    width: '100%',
                    cursor: 'move',
                    display: 'flex',
                    backgroundColor: '#00000080',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
                    <Grid item xs={11}>
                      <Typography variant="body2" component="div" fontWeight={500} sx={{ cursor: 'inherit' }}>
                        Входящий вызов
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="minimize"
                        size="small"
                        sx={{ color: '#e1e1e1', height: '30px', width: '30px' }}
                        onClick={handleMinimizeDialog}
                        title="Свернуть"
                        // onClick={handleCloseZoom}
                      >
                        <CloseFullscreenIcon htmlColor="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
                    <Grid item>{call?.answerAt && <Timer date={call.answerAt} />}</Grid>
                    <Grid item>
                      <Button
                        onClick={handleHangupCall}
                        color="error"
                        size="small"
                        sx={{ minWidth: '32px', padding: '2px' }}
                        variant="contained"
                      >
                        <CallEndIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container item flex={'1 1 auto'} justifyContent="space-between" flexDirection={'column'} alignItems="center">
                  <Grid item>
                    {callStore.data.call && callStore.callMatchOne && (
                      <Box mt={1}>
                        <Box>
                          <Typography variant="h4" gutterBottom sx={{ color: 'white' }} textAlign={'center'}>
                            {callStore.nameClientCall}
                          </Typography>
                          <Typography variant="h5" textAlign={'center'}>
                            {formatPhoneNumberIntl(call?.callerIDNum)}
                          </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'}>
                          <MxLink
                            href={`/app/clients/${encodeURIComponent(call?.contact.customerCode)}`}
                            variant="h6"
                            typographySx={{ display: 'flex', textAlign: 'center', alignItems: 'center', color: 'white', cursor: 'pointer' }}
                          >
                            {callStore.nameCompanyCall}
                          </MxLink>
                        </Box>
                      </Box>
                    )}

                    {callStore.data.call && callStore.callMatchSeveral && (
                      <Box mt={3}>
                        <Box>
                          <Typography variant="h5" gutterBottom textAlign={'center'} color="white">
                            Найдено несколько совпадений
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" gutterBottom textAlign={'center'}>
                            {formatPhoneNumberIntl(call?.callerIDNum)}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {callStore.data.call && !callStore.callMatchSeveral && !callStore.callMatchOne && (
                      <Box mt={2}>
                        <Box>
                          <Typography variant="h5" gutterBottom textAlign={'center'} color="white">
                            Совпадений не найдено
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h5" textAlign={'center'}>
                            {formatPhoneNumberIntl(call?.callerIDNum)}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item pb={2}>
                    <MxLink
                      href={`/app/clients?phone=${encodeURIComponent(call.callerIDNum.replace('+', ''))}`}
                      variant="body2"
                      color="textPrimary"
                      typographySx={{ cursor: 'pointer', textAlign: 'center', color: '#e1e1e1' }}
                    >
                      {!callStore.callMatchSeveral && !callStore.callMatchOne ? 'Выбрать вручную' : 'Показать всех'}
                    </MxLink>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    </Draggable>
  );
});
