import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { useStopwatch } from 'react-timer-hook';

interface TimerProps {
  date: string;
  color?: string;
  variant?: any;
  sx?: any;
}
// "2023-05-15T09:08:12.207712Z"
export const Timer: FC<TimerProps> = ({ date, color, variant = 'body2', sx }): JSX.Element => {
  const offsetTimestamp = new Date(date);
  const date1 = new Date();
  const dif = Math.abs(offsetTimestamp.getTime() - date1.getTime()) + date1.getTime();
  const { seconds, minutes, hours } = useStopwatch({ autoStart: true, offsetTimestamp: new Date(dif) });
  return (
    <Typography variant={variant} color={color ? color : undefined} sx={sx}>
      {hours ? `${String(hours).padStart(2, '0')}:` : ''}
      {String(minutes).padStart(2, '0')}
      {':'}
      {String(seconds).padStart(2, '0')}
    </Typography>
  );
};
