import { FC, ReactNode, useEffect, useRef } from 'react';
import React, { useCallback } from 'react';
import { DrawerNavbar } from './NavBar';
import CookieBar from '../CookieBar/CookieBar';
import { AppBarDeal } from './TopBar/AppBarDeal';
import { observer } from 'mobx-react-lite';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { TopBarMobile } from './TopBar';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { MenuOpenContextProvider } from '@mx-ui/navbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import { CallDialog } from '../Call/CallDialog';
import { ProductItemStore } from 'src/store/ProductItemStore';

const PREFIX = 'DashboardLayout';

const classes = {
  root: `${PREFIX}-root`,
  anchor: `${PREFIX}-anchor`,
  wrapper: `${PREFIX}-wrapper`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`,
  prod: `${PREFIX}-prod`,
  dev: `${PREFIX}-dev`,
  qa: `${PREFIX}-qa`,
  demo: `${PREFIX}-demo`,
};

const Root = styled('div')(() => ({
  // backgroundColor: '#121212',
  positiond: 'relative',
  display: 'flex',
  height: '100%',
  // overflow: 'hidden',
  width: '100%',

  [`& .${classes.anchor}`]: {
    height: 0,
  },

  [`& .${classes.wrapper}`]: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256,
    // },
  },

  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
  },

  [`& .${classes.content}`]: {
    flex: '1 1 auto',
    height: '100%',
    // overflow: 'auto',
  },
  [`& .${classes.prod}`]: {
    backgroundColor: '#505050',
    [`&:hover`]: {
      backgroundColor: '#3e3d3d',
    },
  },
  [`& .${classes.dev}`]: {
    backgroundColor: '#CC0099',
    [`&:hover`]: {
      backgroundColor: '#9b1616',
    },
  },
  [`& .${classes.qa}`]: {
    backgroundColor: '#00695c',
    [`&:hover`]: {
      backgroundColor: '#015046',
    },
  },
  [`& .${classes.demo}`]: {
    backgroundColor: '#111',
    [`&:hover`]: {
      backgroundColor: '#111',
    },
  },
}));

interface DashboardLayoutProps {
  children?: ReactNode;
  labelAppBar?: ReactNode;
  productStore?: ProductItemStore;
}

const DashboardLayout: FC<DashboardLayoutProps> = observer(({ labelAppBar, children, productStore }) => {
  const [isMenuOpenStorage, setIsMenuOpenStorage] = useLocalStorage<boolean>('isMenuOpenStorage', true);
  const handleOnMobileClose = useCallback((): void => {
    setIsMenuOpenStorage(!isMenuOpenStorage);
  }, [isMenuOpenStorage, setIsMenuOpenStorage]);
  const theme = useTheme();
  const notificationsStore = useRootStore().getNotificationsStore();
  useEffect(() => {
    notificationsStore.load(true, false);
  }, [notificationsStore]);
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
  const firstLoad = useRef(false);
  //* магическая магия сворачивание навбара при трех разных экранов
  useEffect(() => {
    if (firstLoad.current) {
      if (matchesSm) {
        setIsMenuOpenStorage(false);
      }
    }
  }, [matchesSm]);
  useEffect(() => {
    if (firstLoad.current) {
      if (!matchesLg && !matchesMd && !matchesSm) {
        setIsMenuOpenStorage(true);
      }
    }
  }, [matchesLg]);
  useEffect(() => {
    if (firstLoad.current) {
      if (!matchesMd && matchesLg) {
        setIsMenuOpenStorage(false);
      }
    }
  }, [matchesLg, matchesMd]);
  useEffect(() => {
    firstLoad.current = true;
  }, []);

  return (
    <Root>
      <MenuOpenContextProvider value={{ isOpen: isMenuOpenStorage, onChangeMenuOpen: handleOnMobileClose, topPosition: matchesSm }}>
        <div id="top-anchor" className={classes.anchor} />
        <DrawerNavbar />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {matchesSm && !labelAppBar && <TopBarMobile />}
              {labelAppBar && <AppBarDeal label={labelAppBar} productStore={productStore && productStore} />}

              {children}
            </div>
          </div>
        </div>
        <CallDialog />
        <CookieBar />
      </MenuOpenContextProvider>
    </Root>
  );
});

export default DashboardLayout;
