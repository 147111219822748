import React from 'react';
import Box from '@mui/material/Box';

interface IStyleContainer {
  column?: boolean;
  fontSize?: string;
  padding?: string;
  margin?: string;
  defaultMargin?: boolean;
  width?: string;
  height?: string;
  maxHeight?: string;
  maxWidth?: string;
  fontWeight?: number;
  circleTop?: boolean;
  circleBottom?: boolean;
  backgroundColor?: string;
  pointer?: boolean;
  noPadding?: boolean;
  middle?: boolean;
  color?: string;
  border?: string;
  justifyContent?: 'center' | 'flex-start' | 'stretch' | 'flex-end' | 'space-between';
  alignItems?: 'center' | 'flex-start' | 'stretch' | 'flex-end';
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const StyleContainer: React.FC<IStyleContainer> = ({
  column,
  fontSize,
  padding,
  margin,
  defaultMargin,
  width,
  height,
  maxHeight,
  maxWidth,
  fontWeight,
  circleTop,
  circleBottom,
  backgroundColor,
  pointer,
  noPadding,
  middle,
  color,
  border,
  justifyContent,
  alignItems,
  style,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
        alignItems: middle ? 'center' : alignItems,
        justifyContent: middle ? 'center' : justifyContent,
        margin: margin || (defaultMargin ? '8px' : '0px'),
        padding: padding || (noPadding ? '0' : '8px'),
        width: width,
        height: height,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        fontWeight: fontWeight,
        border: border,
        borderTopLeftRadius: circleTop ? '5%' : undefined,
        borderTopRightRadius: circleTop ? '5%' : undefined,
        borderBottomLeftRadius: circleBottom ? '5%' : undefined,
        borderBottomRightRadius: circleBottom ? '5%' : undefined,
        fontSize: fontSize,
        cursor: pointer ? 'pointer' : undefined,
        boxSizing: 'border-box',
        color: color,
        backgroundColor: backgroundColor,
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default StyleContainer;
